*:focus-visible,*:focus{
    outline: unset!important;
    box-shadow: unset!important;
}
*:not(.flatpickr-input)[readonly]{
    background-color: rgba(216, 216, 216, 0.5)!important;
}

.react-datepicker__day--today {
    background-color: yellow !important;
    border-radius: 5px;
    color: black;
    font-weight: bold;
}
.rbc-toolbar button{
    color: #6366f1 !important;
    font-weight: 500 !important;
}
.rbc-toolbar .rbc-toolbar-label{
    color: #6366f1 !important;
    font-weight: 500 !important;
}